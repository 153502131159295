<template>
  <step-lesson-layout class="module2-part1-lesson2"
    :title="$t('module2.part1.lesson.interactionBlock.info1')">
    <template v-slot:content>
      <!-- CAS 1 -->
      <div class="infography-wrapper">
        <div class="illu-wrapper">
          <div
            ref="interaction"
            :class="{ hidden: !interactionStarted }"
            class="illu" v-html="mq.tabletMinus ? require('@/assets/module2/part1/cas1-mobile.svg?inline') : require('@/assets/module2/part1/cas1-desktop.svg?inline')"
          />
        </div>
      </div>

      <!-- END BLOCK -->
      <app-button-layout @click="$emit('next-tab')">
        {{ $t('global.navigation.resume') }}
      </app-button-layout>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import interactionAnimationMixin from '@/components/training/module2/part1/interactionAnimationMixin'

export default {
  name: 'Module2Part1Lesson',
  components: { AppButtonLayout, StepLessonLayout },
  mixins: [interactionAnimationMixin],
  inject: ['mq']
}
</script>

<style lang="scss" scoped>
.module2-part1-lesson2 {
  .app-button-layout {
    margin: $space-ml auto;
  }
  .infography-wrapper {
    text-align: center;
    margin-top: $space-s;
    .illu-wrapper {
      background-color: #00935d;
      border-radius: $radius;
    }
    .label {
      color: #00935d;
    }
    .illu-wrapper {
      padding: $space-l $space-s;
      .illu {
        &.hidden {
          visibility: hidden;
        }
      }
    }
  }
}

</style>
