import gsap from 'gsap'

export default {
  mounted () {
    this.startAnimation()
  },
  data () {
    return {
      interactionStarted: false
    }
  },
  methods: {
    startAnimation () {
      const interactionEl = this.$refs.interaction
      this.startInteractionAnimation(interactionEl, () => { this.interactionStarted = true })
    },
    startInteractionAnimation (interactionEl, onStart) {
      const steps = gsap.utils.toArray(interactionEl.querySelectorAll('.step')).reverse()
      const charas = gsap.utils.toArray(interactionEl.querySelectorAll('.chara'))
      const mainChara = interactionEl.querySelector('.main-chara')
      gsap.set(steps, { opacity: 0 })
      gsap.set(charas, { opacity: 0 })
      gsap.set(mainChara, { opacity: 0, scale: 0.5 })

      onStart()

      const mainTl = gsap.timeline()
        .to(mainChara, { transformOrigin: '50% 50%', scale: 1, opacity: 1, ease: 'back', duration: 0.8, smoothOrigin: false })

      const charasTl = gsap.timeline()
      const stepsTl = gsap.timeline()

      charas.forEach((chara) => {
        const tl = gsap.timeline()
        tl.to(chara, { opacity: 1, duration: 0.6 })
        charasTl.add(tl, '=')
      })

      mainTl.add(charasTl, '>')

      steps.forEach((step) => {
        const stepTl = gsap.timeline()
        const illu = step.querySelector('.illu')
        const bullet = step.querySelector('.bullet')
        const txt = step.querySelector('.txt')
        const arrows = gsap.utils.toArray(step.querySelectorAll('.arrow > *'))
          .sort((a, b) => a.dataset.arrowOrder - b.dataset.arrowOrder)
        gsap.set(illu, { opacity: 0 })
        gsap.set(bullet, { opacity: 0, scale: 0 })
        arrows.forEach(arrow => {
          gsap.set(arrow, { opacity: 0, strokeDasharray: `${arrow.getTotalLength()}px`, strokeDashoffset: `${arrow.getTotalLength()}px` })
        })
        gsap.set(txt, { opacity: 0, xPercent: 10 })
        gsap.set(step, { opacity: 1 })

        stepTl.to(bullet, { transformOrigin: '50% 50%', scale: 1, opacity: 1, ease: 'back', duration: 0.4, smoothOrigin: false })
          .to(txt, { xPercent: 0, ease: 'back', opacity: 1, duration: 0.6 }, '-=0.1')
          .to(illu, { opacity: 1, duration: 1 }, '<')

        const arrowsTl = gsap.timeline()
        if (arrows) {
          arrows.forEach(arrow => {
            const arrowTl = gsap.timeline()
            const offset = arrow.className.baseVal.includes('left') ? arrow.getTotalLength() * 2 : 0
            arrowTl.to(arrow, { opacity: 1, strokeDashoffset: offset, duration: arrow.getTotalLength() * 0.005 })
            arrowsTl.add(arrowTl, '>')
          })
        }

        stepTl.add(arrowsTl, '0')
        stepsTl.add(stepTl, '+=1')
      })

      mainTl.add(stepsTl, '>')
    }
  }
}
